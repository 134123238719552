import React from "react";
import { Link } from "react-router-dom";

import SearchIcon from "../../assets/Service_Page/search-icon.png";
import DiscordIcon from "../../assets/Service_Page/discord-icon.png";
import FacebookIcon from "../../assets/Service_Page/facbk-icon.png";
import InstagramIcon from "../../assets/Service_Page/insta-icon.png";
import LinkedInIcon from "../../assets/Service_Page/linkd-icon.png";
import SpotifyIcon from "../../assets/Service_Page/sptfy-icon.png";
import TwitterIcon from "../../assets/Service_Page/twit-icon.png";
import WebIcon from "../../assets/Service_Page/web-icon.png";
import YouTubeIcon from "../../assets/Service_Page/yt-icon.png";

function SearchPanel() {
  return (
    <div className="search-panel-wrapper">
      <section className="search-panel-title">
        <h1>Find Your Perfect SMM Solution</h1>
      </section>
      <section className="search-panel-search-box">
        <div className="search-box-wrapper">
          <img src={SearchIcon} alt="search-icon" />
          <input type="search" placeholder="Search here" />
        </div>
      </section>
      <section className="search-panel-social-handle">
        <Link>
          <div className="social-handle-icon-text">
            <img src={InstagramIcon} alt="" />
            <p>Instagram</p>
          </div>
        </Link>

        <Link>
          <div className="social-handle-icon-text">
            <img src={LinkedInIcon} alt="" />
            <p>LinkedIn</p>
          </div>
        </Link>

        <Link>
          <div className="social-handle-icon-text">
            <img src={FacebookIcon} alt="" />
            <p>Facebook</p>
          </div>
        </Link>

        <Link>
          <div className="social-handle-icon-text">
            <img src={TwitterIcon} alt="" />
            <p>X</p>
          </div>
        </Link>

        <Link>
          <div className="social-handle-icon-text">
            <img src={YouTubeIcon} alt="" />
            <p>Youtube</p>
          </div>
        </Link>

        <Link>
          <div className="social-handle-icon-text">
            <img src={SpotifyIcon} alt="" />
            <p>Spotify</p>
          </div>
        </Link>

        <Link>
          <div className="social-handle-icon-text">
            <img src={DiscordIcon} alt="" />
            <p>Discord</p>
          </div>
        </Link>

        <Link>
          <div className="social-handle-icon-text">
            <img src={WebIcon} alt="" />
            <p>Website</p>
          </div>
        </Link>
      </section>
    </div>
  );
}

export default SearchPanel;
