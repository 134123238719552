const termsData = [
  {
    terms1: [
      {
        title: "Terms of Service Agreement",
        content:
          "By placing an order with SMM Panel, you automatically accept all the below-listed terms of service whether you read them or not. We reserve the right to change these terms of service without notice. You are expected to read all terms of service before placing an order to ensure you are up to date with any changes or any future changes. You will only use the SMM Panel website in a manner which follows all agreements made with Instagram/Facebook/Twitter/YouTube/Other social media sites on their Terms of Service page. SMM Panel rates are subject to change at any time without notice. The payment/refund policy stays in effect in the case of rate changes. SMM Panel does not guarantee a delivery time for any services. We offer our best estimation for when the order will be delivered. This is only an estimation, and SMM Panel will not refund orders that are processing if you feel they are taking too long. SMM Panel tries hard to deliver exactly what is expected from us by our resellers. In this case, we reserve the right to change a service type if we deem it necessary to complete an order.",
      },

      {
        title: "Disclaimer",
        content:
          "SMM Panel will not be responsible for any damages you or your business may suffer.",
      },

      {
        title: "Liabilities",
        content:
          "SMM Panel  is in no way liable for any account suspension or picture deletion done by Instagram or Twitter or Facebook or YouTube or Other Social Media.",
      },
    ],
  },

  {
    title: "Payment info",
    content:
      "We use Stripe and Paypal as our exclusive payment processing chonestsmmanies. We will never contact you asking for any sort of personal information.",
  },
  {
    title: "Refund Policy",
    content:
      "No refunds will be made to your payment method. After a deposit has been chonestsmmleted, there is no way to reverse it. You must use your balance on orders from honestsmm. You agree that once you chonestsmmlete a payment, you will not file a dispute or a chargeback against us for any reason. If you file a dispute or charge-back against us after a deposit, we reserve the right to terminate all future orders, ban you from our site. We also reserve the right to take away any followers or likes we delivered to you or your clients' Instagram/Facebook/Twitter or other social media account. Orders placed in honestsmm will not be refunded or canceled after they are placed. You will receive a refund credit to your honestsmm account if the order is non-deliverable. Misplaced or Private account orders will not qualify for a refund. Be sure to confirm each and every order before placing it. Fraudulent activity, such as using unauthorized or stolen credit cards, will lead to termination of your account. There are no exceptions. Please do not use more than one server at the same time for the same page. We cannot give you the correct followers/likes number in that case. We will not refund these orders.",
  },
  {
    title: "Privacy Policy",
    content:
      "His policy covers how we use your personal information. We take your privacy seriously and will take all measures to protect your personal information. Any personal information received will only be used to fill your order. We will not sell or redistribute your personal information to anyone. All personal information is encrypted and saved in secure servers.",
  },
];

export default termsData;
