import React from "react";
import CartIcon from "../../assets/Service_Page/cart-icon.png";
import DollarIcon from "../../assets/Service_Page/dollar-icon.png";
import FilterIcon from "../../assets/Service_Page/filter-icon.png";
import InfoIcon from "../../assets/Service_Page/info-icon.png";
import { Link } from "react-router-dom";

function ServicePanel() {
  return (
    <>
      <div id="service-panel-main-container">
        <div className="service-panel-wrapper">
          <section className="services-filters-bar">
            <h2>
              Sort By : <span>12 services</span>
            </h2>
            <div className="filters-wrapper">
              <div className="categories-filter">
                <img src={FilterIcon} alt="filter-icon" />
                <select name="category" id="">
                  <option value="">Filter by category of services</option>
                  <option value=""></option>
                  <option value=""></option>
                </select>
              </div>
              <div className="payment-filter">
                <img src={DollarIcon} alt="dollar-icon" />
                <select name="payment-currency" id="">
                  <option value="">USD $</option>
                  <option value=""></option>
                  <option value=""></option>
                </select>
              </div>
            </div>
          </section>

          <section className="services-table">
            <table>
              <thead>
                <tr className="table-head">
                  <th>ID</th>
                  <th>Service</th>
                  <th>
                    <select name="" id="">
                      <option value="">Rate per 100</option>
                      <option value=""></option>
                      <option value=""></option>
                    </select>
                  </th>
                  <th>Min order</th>
                  <th>Max order</th>
                  <th>
                    <select name="" id="">
                      <option value="">Avg Time</option>
                      <option value=""></option>
                      <option value=""></option>
                    </select>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-body">
                  <td>#1254</td>
                  <td>
                    Instagram Followers | Max 1M | 30k-50k/Day | 0-5% Drop Only
                    | No Refill |🔥
                  </td>
                  <td> ₹73.6971</td>
                  <td>10</td>
                  <td>5,000 000</td>
                  <td>5 hours 25 minutes</td>
                  <td className="icon-container">
                    <Link>
                      <img src={CartIcon} alt="cart-icon" />
                    </Link>
                    <Link>
                      <img src={InfoIcon} alt="info-icon" />
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </>
  );
}

export default ServicePanel;
