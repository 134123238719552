import React from "react";
import { Link } from "react-router-dom";
import twitterIcon from "../../assets/API_page/twitter.png";
import facebookIcon from "../../assets/API_page/facebook.png";
import instagramIcon from "../../assets/API_page/instagram.png";
import linkedInIcon from "../../assets/API_page/linkedin.png";

function Footer() {
  const dateHandler = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  };
  const gototop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <footer className="footer-component-wrapper">
      <section className="fcw-section-top">
        <div className="fcw-st-section-1">
          <section className="fwc-st-s1-head">
            <Link to="/" onClick={gototop}>
              <p>
                SMM
                <span>Panel</span>
              </p>
            </Link>
          </section>
          <section className="fwc-st-s1-body">
            <p>
              Boost your social media with our reliable SMM panel. Fast, secure,
              and affordable services for followers, likes, views, and more.
              Perfect for businesses, influencers, and resellers. 24/7 support
              available!
            </p>
          </section>
          <section className="fwc-st-s1-foot">
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twitterIcon}
                alt="twitter/x-icon"
                className="footer-social-handle-icon"
              />
            </a>

            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebookIcon}
                alt="facebook/meta-icon"
                className="footer-social-handle-icon"
              />
            </a>

            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagramIcon}
                alt="instagram-icon"
                className="footer-social-handle-icon"
              />
            </a>

            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedInIcon}
                alt="linkedin-icon"
                className="footer-social-handle-icon"
              />
            </a>
          </section>
        </div>
        <div className="fcw-st-section-2">
          <section className="fcw-st-s2-quick-link">
            <h4>Quick Link</h4>
            <Link to="/service" onClick={gototop}>
              <p>Service</p>
            </Link>
            <Link to="/api" onClick={gototop}>
              <p>Api Integration</p>
            </Link>
            <Link to="/terms" onClick={gototop}>
              <p>Terms</p>
            </Link>
            <Link to="/#home-reseller">
              <p>Reseller</p>
            </Link>
          </section>

          <section className="fcw-st-s2-social-handles-grow">
            <h4>Social Handles Grow</h4>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Instagram</p>
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Facebook</p>
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>LinkedIn</p>
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>X</p>
            </a>
          </section>

          <section className="fcw-st-s2-Legal">
            <h4>Legal</h4>
            <Link to="/terms/refund-policy" onClick={gototop}>
              <p>Refund Policy</p>
            </Link>
            <Link to="/#home-faqs">
              <p>FAQ</p>
            </Link>
            <Link to="/terms/privacy-policy onClick={gototop}">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/terms/terms-and-conditions" onClick={gototop}>
              <p>Terms and Conditions</p>
            </Link>
          </section>
        </div>
      </section>

      <section className="fcw-section-bottom">
        <hr />
        <p>© Copyright {dateHandler()}, All Rights Reserved</p>
      </section>
    </footer>
  );
}

export default Footer;
