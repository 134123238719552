import React from "react";

const Highlightedtect = ({ text }) => {
  return (
    <span className="text-[#3D55CC] font-semibold text-center max-w-fit">
      {text}
    </span>
  );
};

export default Highlightedtect;
