import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DropDownImg from "./drop-down-menu.png";

function Navbar() {
  const [showDropDown, setShowDropDown] = useState(false);
  const refElement = useRef();
  const handleDropDown = () => {
    setShowDropDown(!showDropDown);
    refElement.current.style.display = showDropDown ? "flex" : "none";
  };

  const handleSelectTab = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (window.innerWidth <= 900) {
      setShowDropDown(false);
      refElement.current.style.display = showDropDown ? "flex" : "none";
    }
  };

  const gototop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="nav-bar-wrapper">
      <section className="nav-bar-logo">
        <strong className="nav-bar-logo-text">
          <Link to="/" onClick={gototop}>
            SMM <span>Panel</span>
          </Link>
        </strong>
        <img
          src={DropDownImg}
          alt=""
          className="drop-down-img"
          onClick={handleDropDown}
        />
      </section>
      <section className="nav-bar-menu" ref={refElement}>
        <Link to="/" className="nav-bar-menu-items" onClick={gototop}>
          Home
        </Link>
        <Link
          to="/service"
          className="nav-bar-menu-items"
          onClick={handleSelectTab}
        >
          Service
        </Link>
        <Link
          to="/api"
          className="nav-bar-menu-items"
          onClick={handleSelectTab}
        >
          API
        </Link>
        <Link
          to="/terms"
          className="nav-bar-menu-items"
          onClick={handleSelectTab}
        >
          Terms
        </Link>
        <Link
          to="/sign-up"
          className="nav-bar-sign-up-btn"
          onClick={handleSelectTab}
        >
          Sign up
        </Link>
      </section>
    </div>
  );
}

export default Navbar;
