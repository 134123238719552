import React from "react";
import Hero from "../API_Page/Hero";
import DevAPISection from "../API_Page/DevAPISection";
import ServicesList from "../API_Page/ServicesList";
import Hero6 from "../HomeComponents/Hero6";
import Faq from "../HomeComponents/Accordion";
function API() {
  return (
    <div>
      <Hero />
      <DevAPISection />
      <ServicesList />
      <Hero6 />
      <Faq />
    </div>
  );
}

export default API;
