import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Hero2 from "../HomeComponents/Hero2";
import Hero3 from "../HomeComponents/Hero3";
import Hero4 from "../HomeComponents/Hero4";
import Hero5 from "../HomeComponents/Hero5";
import Hero6 from "../HomeComponents/Hero6";
import Accordion from "../HomeComponents/Accordion";
import Form from "../HomeComponents/Form";
import Signup from "../HomeComponents/Signup";
import HomePageSearchComponent from "../HomeComponents/HomePageSearchComponent";

function SignUp() {
  const [showLogin, setShowLogin] = useState(false);
  const location = useLocation();

  const toggleForm = () => {
    setShowLogin(!showLogin);
  };

  useEffect(() => {
    if (location.hash === "#home-faqs") {
      const faqSection = document.getElementById("home-faqs");
      if (faqSection) {
        faqSection.scrollIntoView({ behavior: "smooth" });
      }
    } else if (location.hash === "#home-reseller") {
      const resellerSec = document.getElementById("home-reseller");
      if (resellerSec) {
        resellerSec.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <div className="w-full h-full flex flex-col bg-[#FFFFFF]">
        <div
          id="img"
          className="flex flex-col items-center w-full mb-3 py-5 lg:h-[1158.13px] md:h-[900px] h-auto mt-[70px]"
        >
          <HomePageSearchComponent />
          {showLogin ? (
            <Form toggleForm={toggleForm} />
          ) : (
            <Signup toggleForm={toggleForm} />
          )}
        </div>
        <div className="flex flex-col ">
          <Hero2 />
          <Hero3 />
          <Hero4 />
          <Hero5 />
          <Accordion />
          <Hero6 />
        </div>
      </div>
    </>
  );
}

export default SignUp;
