import { React, useRef } from "react";
import searchIcon1 from "../../assets/HomePage/ion_search-1.png";
import searchIcon2 from "../../assets/HomePage/ion_search-2.png";
function HomePageSearchComponent() {
  const refElement1 = useRef();
  const refElement2 = useRef();
  return (
    <div className="home-page-search-comp-wrapper">
      <section className="text-wrapper">
        <p>
          Boost Hub - <span id="highlighted-txt">Supercharge</span> Your Social
          Media Metrics
        </p>
        {/* <p></p> */}
      </section>
      <section className="search-box-wrapper">
        <div className="search-box-inner-wrapper">
          <input
            type="text"
            placeholder="Search service for social handle..."
          />

          <img src={searchIcon1} alt="" ref={refElement1} />
        </div>
        <div className="search-btn">
          <img src={searchIcon2} alt="" ref={refElement2} />
          <p>Search</p>
        </div>
      </section>
    </div>
  );
}

export default HomePageSearchComponent;
