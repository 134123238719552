import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TermsData from "./terms.js";
import termsData from "./terms.js";

function Main() {
  const [terms, setTerms] = useState(TermsData);

  const [isActive, setIsActive] = useState({
    generalInfo: true,
    paymentInfo: false,
    refundPolicy: false,
    privacyPolicy: false,
  });

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/terms/privacy-policy":
        setIsActive({
          generalInfo: false,
          paymentInfo: false,
          refundPolicy: false,
          privacyPolicy: true,
        });
        break;
      case "/terms/refund-policy":
        setIsActive({
          generalInfo: false,
          paymentInfo: false,
          refundPolicy: true,
          privacyPolicy: false,
        });
        break;
      case "/terms/terms-and-conditions":
        setIsActive({
          generalInfo: true,
          paymentInfo: false,
          refundPolicy: false,
          privacyPolicy: false,
        });
        break;
      default:
        setIsActive({
          generalInfo: true,
          paymentInfo: false,
          refundPolicy: false,
          privacyPolicy: false,
        });
        break;
    }
  }, [location.pathname]);

  const toggleTab = (tab) => {
    setIsActive((preState) => ({
      generalInfo: tab == "generalInfo" ? (preState.generalInfo = true) : false,
      paymentInfo: tab == "paymentInfo" ? (preState.paymentInfo = true) : false,
      refundPolicy:
        tab == "refundPolicy" ? (preState.refundPolicy = true) : false,
      privacyPolicy:
        tab == "privacyPolicy" ? (preState.privacyPolicy = true) : false,
    }));
  };

  const renderContent = () => {
    if (isActive.generalInfo) {
      return (
        <>
          {termsData[0].terms1.map((item, index) => (
            <div className="terms-main-sec-content-sec-1" key={index}>
              <h2>{item.title}</h2>
              <p>{item.content}</p>
            </div>
          ))}
        </>
      );
    } else if (isActive.paymentInfo) {
      return (
        <div className="terms-main-sec-content-sec-1">
          <h2>{termsData[1].title}</h2>
          <p>{termsData[1].content}</p>
        </div>
      );
    } else if (isActive.refundPolicy) {
      return (
        <div className="terms-main-sec-content-sec-1">
          <h2>{termsData[2].title}</h2>
          <p>{termsData[2].content}</p>
        </div>
      );
    } else if (isActive.privacyPolicy) {
      return (
        <div className="terms-main-sec-content-sec-1">
          <h2>{termsData[3].title}</h2>
          <p>{termsData[3].content}</p>
        </div>
      );
    }
  };

  return (
    <div className="terms-main-sec-wrapper">
      <section className="terms-main-sec-options-tab">
        <ul>
          <li
            className={isActive.generalInfo ? "active-terms-tab" : ""}
            onClick={() => toggleTab("generalInfo")}
          >
            <button className={isActive.generalInfo ? "terms-btn-active" : " "}>
              General Info
            </button>
          </li>
          <li
            className={isActive.paymentInfo ? "active-terms-tab" : ""}
            onClick={() => toggleTab("paymentInfo")}
          >
            <button className={isActive.paymentInfo ? "terms-btn-active" : " "}>
              Payment Info
            </button>
          </li>
          <li
            className={isActive.refundPolicy ? "active-terms-tab" : ""}
            onClick={() => toggleTab("refundPolicy")}
          >
            <button
              className={isActive.refundPolicy ? "terms-btn-active" : " "}
            >
              Refund Policy
            </button>
          </li>
          <li
            className={isActive.privacyPolicy ? "active-terms-tab" : ""}
            onClick={() => toggleTab("privacyPolicy")}
          >
            <button
              className={isActive.privacyPolicy ? "terms-btn-active" : " "}
            >
              Privacy Policy
            </button>
          </li>
        </ul>
      </section>
      <section className="terms-main-sec-content">{renderContent()}</section>
    </div>
  );
}

export default Main;
