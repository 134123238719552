export const questions = [
  {
    id: 1,
    question: "What is the virtual DOM?",
    answer:
      "DOM stands for Document Object Model. The DOM represents an HTML document",
  },
  {
    id: 2,
    question: "What is the virtual DOM?",
    answer:
      "DOM stands for Document Object Model. The DOM represents an HTML document",
  },
  {
    id: 3,
    question: "What is the virtual DOM?",
    answer:
      "DOM stands for Document Object Model. The DOM represents an HTML document",
  },
  {
    id: 4,
    question: "What is the virtual DOM?",
    answer:
      "DOM stands for Document Object Model. The DOM represents an HTML document",
  },
  {
    id: 5,
    question: "What is the virtual DOM?",
    answer:
      "DOM stands for Document Object Model. The DOM represents an HTML document",
  },
  {
    id: 6,
    question: "What is the virtual DOM?",
    answer:
      "DOM stands for Document Object Model. The DOM represents an HTML document",
  },
];
