import React from "react";

import Hero from "../Terms_Page/Hero.jsx";
import Main from "../Terms_Page/Main.jsx";
import { act } from "react-dom/test-utils";
import { Outlet } from "react-router-dom";

function Terms() {
  return (
    <div>
      <Hero />
      <Main />
    </div>
  );
}

export default Terms;
