import React from "react";
import SearchPanel from "../Service_Page/SearchPanel";
import ServicePanel from "../Service_Page/ServicePanel";

function Service() {
  return (
    <div className="service-panel-page">
      <SearchPanel />
      <ServicePanel />
    </div>
  );
}

export default Service;
